import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ArthaGateway from 'service/ArthaGateway';
import { JobOrder, JobOrderItem, Production, Supervisor } from 'service';
import swal from 'sweetalert';

export const JobDetails = () => {
    const userData = useSelector((state: RootState) => state.appdata);
    const [user, setUser] = useState<any>();
    const [supervisorId, setSupervisorId] = useState<number>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [jobOrder, setJobOrder] = useState<JobOrder>();
    const [items, setItems] = useState<JobOrderItem[]>([]);
    const [stageId, setStageId] = useState<number>(2);
    const [totalQuantity, setTotalQuantity] = useState<number>(0);
    const [screenName, setScreenName] = useState<string>("entry");
    const [useInputForEntry, setUseInputForEntry] = useState<boolean>(false);
    const [supervisorPin, setSupervisorPin] = useState<string>("");
    const [productionStages, setProductionStages] = useState<any[]>([]);
    const { orderNumber } = useParams();
    const [supervisors, setSupervisors] = useState<Supervisor[]>();

    const handleFocus = (e) => e.target.select();

    useEffect(() => {
        if (userData.profile) {
            setUser(userData.profile.profile);
            loadSupervisors();
        }
        setIsLoading(true);
        ArthaGateway
            .getJobDetails(orderNumber)
            .then((data) => {
                if (data) {
                    data.items.forEach((item) => {
                        item.eligible = 0;
                        item.produced = 0;
                        item.pending = 0;
                    });
                }
                setJobOrder(data);
                setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }, [user]);

    const loadSupervisors = () => {
        let storageData = sessionStorage.getItem("supervisors");
        if (storageData != null && storageData.length > 0) {
            setSupervisors(JSON.parse(storageData));
            return;
        }

        ArthaGateway
            .loadSupervisors()
            .then((data) => {
                setSupervisors(data);
                sessionStorage.setItem("supervisors", JSON.stringify(data));
            })
            .catch(error => {
                console.log(error);
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (!user) {
            return;
        }
        let stages = [
            { id: 2, name: 'Cutting', isActive: true },
            { id: 4, name: 'Fuzing', isActive: true },
            { id: 5, name: 'Loading', isActive: true },
            { id: 6, name: 'Stiching', isActive: true },
            { id: 8, name: 'Kaja Button', isActive: true },
            { id: 10, name: 'Ironing', isActive: true },
            { id: 14, name: 'Folding', isActive: true },
            { id: 15, name: 'Flat', isActive: true },
            { id: 12, name: 'Packing', isActive: true }
        ]
        setProductionStages(stages);
        for (let i = 0; i < stages.length; i++) {
            if (stages[i].isActive) {
                setStageId(stages[i].id);
                break;
            }
        }
    }, [user]);

    useEffect(() => {
        if (stageId > 0 && jobOrder) {
            calculateEligibleQuantity();
        }
    }, [stageId, jobOrder]);

    useEffect(() => {
        if (items) {
            setTotalQuantity(items.reduce((total, item) => total + item.quantity, 0));
        }
        else {
            setTotalQuantity(0);
        }
    }, [items]);

    const calculateEligibleQuantity = () => {
        console.log("Calculating eligible quantity, StageId = " + stageId);
        if (!jobOrder || stageId <= 0) {
            console.log("Job order or stage id is not valid, Stage Id: " + stageId + ", Job Order: " + jobOrder);
            return;
        }
        let currentStage = "Cutting";
        let lastStage = "Cutting";
        for (var j in productionStages) {
            if (productionStages[j].id == stageId) {
                currentStage = productionStages[j].name;
                break;
            }
            lastStage = productionStages[j].name;
        }

        currentStage = currentStage.charAt(0).toLowerCase() + currentStage.substring(1);
        lastStage = lastStage.charAt(0).toLowerCase() + lastStage.substring(1);
        console.log("Current Stage: " + currentStage + ", Last Stage: " + lastStage);
        jobOrder.items.forEach((item, index) => {
            if (stageId == 2) {
                item.eligible = jobOrder.production[index].targetQuantity;
            }
            else {
                item.eligible = jobOrder.production[index].production[lastStage];
            }
            item.produced = jobOrder.production[index].production[currentStage];
            item.pending = item.eligible - item.produced;
            item.quantity = 0;
            console.log("Size: " + item.size + ", Eligible: " + item.eligible + ", Produced: " + item.produced + ", Pending: " + item.pending + ", Quantity: " + item.quantity);
        });
        setItems([...jobOrder.items]);
    }

    const updateItemQuantity = (index: number, quantity) => {
        if (index < 0 || index >= items.length) {
            console.log("Invalid index: " + index);
            return;
        }
        if(quantity < 0) {
            quantity = 0;
        }

        if(stageId > 2) {
            if(quantity > items[index].pending) {
                quantity = items[index].pending;
            }
        }
        items[index].quantity = quantity;
        setItems([...items]);
    }

    const saveProduction = () => {
        if (!jobOrder || stageId <= 0) {
            return;
        }
        let isValid = true;
        jobOrder.items.forEach((item) => {
            if (item.quantity > item.pending) {
                isValid = false;
            }
        });

        if (!isValid) {
            swal("Plese enter valid quantity, Quantity is greater than pending quantity", "", "error");
            return;
        }

        setIsLoading(true);
        let production: Production = {
            date: moment().format("YYYY-MM-DD"),
            notes: "",
            jobId: jobOrder.id,
            jobOrderNumber: jobOrder.voucherNumber,
            stageId: stageId,
            supervisorId: supervisorId ? supervisorId : 0,
            items: items
        };
        ArthaGateway.saveProduction(production)
            .then((data) => {
                setIsLoading(false);
                if (data) {
                    swal("Production saved successfully", "", "success");
                    setTimeout(() => window.location.href = "/", 2000);
                }
            })
            .catch(error => {
                console.log(error);
                swal("Error saving production", "", "error");
            });

        return;
    }

    return (
        <div className="page-jobDetails">
            <div className='page-header bg-dark' style={{ paddingBottom: "5px" }}>
                <h2>{orderNumber}</h2>
            </div>
            {isLoading &&
                <Loader />
            }
            {!isLoading && jobOrder &&
                <div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th style={{ width: 50 }}>School</th>
                                <td>{jobOrder.brand}</td></tr>
                            <tr>
                                <th>Group</th>
                                <td>{jobOrder.group}</td>
                            </tr>
                            <tr>
                                <th>Product</th>
                                <td>{jobOrder.master}</td>
                            </tr>
                            <tr>
                                <th >Date</th>
                                <td>{moment(jobOrder.date).format("DD MMMM YYYY")}</td>
                            </tr>
                            <tr>
                                <th >Stage</th>
                                <td>
                                    <div className='form-control' style={{ padding: 0 }}>
                                        <select className='form-select' value={stageId} onChange={(e) => setStageId(parseInt(e.target.value))}>
                                            {productionStages.map((stage) => (
                                                <>
                                                    {stage.isActive &&
                                                        <option key={stage.id} value={stage.id}>{stage.name}</option>
                                                    }
                                                </>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th >Supervisor</th>
                                <td>
                                    <div className='form-control' style={{ marginBottom: 20, padding: 0 }}>
                                        <select className='form-select' value={supervisorId} onChange={(e) => setSupervisorId(parseInt(e.target.value))}>
                                            {supervisors?.map((supervisor) => (
                                                <>
                                                    {supervisor.stages.indexOf(stageId) >= 0 &&
                                                        <option key={supervisor.id} value={supervisor.id}>{supervisor.name}</option>
                                                    }
                                                </>
                                            ))}
                                        </select>
                                    </div>
                                </td>
                            </tr>
                            {screenName === "confirm" &&
                                <tr>
                                    <th>Total Quantity</th>
                                    <td>
                                        <div className='form-control' style={{ padding: 0 }}>
                                            <input type="number" className="form-control" style={{ textAlign: "right" }} value={totalQuantity} disabled />
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    {screenName === "entry" &&
                        <>
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan={4}>
                                            <div className="form-check form-switch" style={{ float: "right" }}>
                                                <label className="form-check-label">Enter quantity</label>
                                                <input className="form-check-input" 
                                                    type="checkbox" 
                                                    role="switch" 
                                                    checked={useInputForEntry}
                                                    onChange={(e) => setUseInputForEntry(e.target.checked)} />
                                            </div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th>Size</th>
                                        <th style={{ width: 50 }}>Target</th>
                                        <th style={{ width: 50 }}>Produced</th>
                                        <th style={{ width: 100 }}>Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((item, index) => (
                                        <tr key={item.id}>
                                            <th style={{ width: 100 }}>{item.size}</th>
                                            <td style={{ textAlign: 'right' }}>{jobOrder.production[index].targetQuantity}</td>
                                            <td style={{ textAlign: 'right' }}>{item.produced} / {item.eligible}</td>
                                            <td style={{ padding: 0 }}>
                                                {item.pending > 0 &&
                                                    <>
                                                        {!useInputForEntry &&
                                                            <select key={"q_" + item.id}
                                                                className='form-select'
                                                                value={item.quantity}
                                                                onChange={(e) => updateItemQuantity(index, parseInt(e.target.value))}>
                                                                <option value={0}>0</option>
                                                                {Array.from(Array(item.pending).keys()).map((i) => (
                                                                    <option key={i + 1} value={i + 1}>{i + 1}</option>
                                                                ))}
                                                            </select>
                                                        }
                                                        {useInputForEntry &&
                                                            <input key={"iq_" + item.id}
                                                                type="number"
                                                                className="form-control"
                                                                style={{ textAlign: "right" }}
                                                                value={item.quantity}
                                                                onChange={(e) => updateItemQuantity(index, parseInt(e.target.value))}
                                                                onFocus={handleFocus} />
                                                        }
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            {totalQuantity > 0 &&
                                <div className='btn btn-primary bg-dark'
                                    style={{ textAlign: "center", padding: "10px 0px", width: "100%" }}
                                    onClick={() => {
                                        setSupervisorPin("");
                                        setScreenName('confirm');
                                    }}>Confirm</div>
                            }
                        </>
                    }
                    {screenName === "confirm" &&
                        <>
                            <input type="number"
                                autoFocus
                                className="form-control"
                                placeholder="Enter Supervisor Pin"
                                style={{ textAlign: "center", marginBottom: 20 }}
                                value={supervisorPin}
                                onChange={(e) => setSupervisorPin(e.target.value)} />

                            <div className='btn btn-primary bg-danger'
                                style={{ textAlign: "center", padding: "10px 0px", width: "50%" }}
                                onClick={() => {
                                    setScreenName("entry");
                                    setSupervisorPin("");
                                }}>Back</div>
                            {supervisorPin.length > 0
                                && supervisorPin === supervisors?.find((supervisor) => supervisor.id === supervisorId)?.password &&
                                <div className='btn btn-primary bg-dark'
                                    style={{ textAlign: "center", padding: "10px 0px", width: "50%" }}
                                    onClick={() => saveProduction()}>Save</div>
                            }
                        </>
                    }
                </div>
            }
        </div>
    );
}