import { InstallPWA, Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { SalesPerson } from 'service';
import ArthaGateway from 'service/ArthaGateway';
import { ActionCreators } from 'store/actionCreators';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { RootState } from 'store/store';
import { app_version, clearCache } from 'service/Utils';

export const Login = () => {    
    const user = useSelector((state: RootState) => state.appdata);
    const [userName, setUserName] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [isValidating, setIsValidating] = useState<boolean>(false);
    const dispatch = useDispatch();

    const validateLogin = () => {
        console.log("Validating the login");
        setIsValidating(true);
        ArthaGateway
        .validateLogin(userName, password)
        .then((data) => {
            const user = {
                userName: data.name,
                name: data.name,
                profile: data
            };
            localStorage.setItem("login_time", moment().format());
            dispatch(ActionCreators.login(user));            
            setIsValidating(false);
            setTimeout(() => window.location.href = "/", 500);
        })
        .catch(error => {
            console.log(error);
            setShowAlert(true);
            setUserName("");
            setPassword("");
            setTimeout(() => setShowAlert(false), 10000);
            setIsValidating(false);
        });
    };
    
    return (
        <form>
            <InstallPWA />
            <div className='login-form'>
                <div className='container'>                    
                    {showAlert &&
                        <div className="alert alert-danger" role="alert" style={{ marginBottom: 20 }}>
                            The user name\password you entered does not match with any of the records.
                        </div>
                    }
                    <div className="form-outline mb-4">
                        <label className="form-label">Username</label>
                        <input type="text" className="form-control" value={userName} onChange={(e) => setUserName(e.target.value)} />
                    </div>
                    <div className="form-outline mb-4">
                        <label className="form-label" >Password</label>
                        <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    {(password.length > 1) &&
                        <div className="form-outline mb-4" style={{ width: "100%" }}>
                            <div className='btn btn-dark w-100' onClick={validateLogin}>
                                {isValidating &&
                                    <div className="spinner-border text-light" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                }
                                 {!isValidating &&
                                    <>
                                        <FontAwesomeIcon icon={faRightToBracket} style={{ marginRight: 10 }} />
                                        Login
                                    </>
                                 }
                            </div>
                        </div>
                    }
                    <div className='version-number'>
                        <div className="btn btn-sm bg-dark" style={{ marginLeft: 20, marginBottom: 10, color: "#fff" }} onClick={clearCache}>Update to latest version</div>
                        <span style={{ marginRight: 20, float: "right" }} >{ app_version }</span>
                    </div>
                </div>
            </div>
        </form>
    );
};