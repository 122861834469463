import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { User } from 'service';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ArthaGateway from 'service/ArthaGateway';

export const Dashboard = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [supervisor, setUser] = useState<User>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [tab, setTab] = useState<string>("search");
    const [jobNumber, setJobNumber] = useState<string>("");

    useEffect(() => {
        if (user.profile) {
            setUser(user.profile.profile);
            console.log(user.profile.profile);
            setIsLoading(false);
        }
    }, [user]);

    function searchJobOrder(): void {
        window.location.href = "/jobOrder/" + jobNumber;
    }

    return (
        <>
            <div className="page-dashboard">
                <div className='page-header bg-dark' style={{ paddingBottom: "20px" }}>
                    Welcome,
                    <h2>{user?.name}</h2>
                </div>
                {isLoading &&
                    <Loader />
                }
                {!isLoading &&
                    <>
                        {/*<ul className="nav nav-tab">
                            <li className="nav-item" onClick={() => setTab("search")} style={{ width: "50%" }}>
                                <h2 className={(tab === "search") ? "nav-link active" : "nav-link"}>Search</h2>
                            </li>
                            <li className="nav-item" onClick={() => setTab("select")} style={{ width: "50%" }}>
                                <h2 className={(tab === "select") ? "nav-link active" : "nav-link"}>Select</h2>
                            </li>
                </ul>*/}
                        {(tab === "search") &&
                            <div className="content-holder">
                                <div>
                                    <div className="form-group">
                                        <input type="number" autoFocus className="form-control" placeholder="Enter Job Number" value={jobNumber} onChange={(e) => setJobNumber(e.target.value)} />
                                    </div>
                                    {jobNumber && jobNumber.length > 0 &&
                                        <div className="btn btn-primary bg-dark"
                                            style={{ textAlign: "center", margin: "10px 0px", width: "100%" }}
                                            onClick={searchJobOrder}>Search</div>
                                    }
                                </div>
                            </div>
                        }
                        {(tab === "select") &&
                            <div>

                            </div>
                        }
                    </>
                }
            </div>
        </>
    );
};