import { Loader } from '../Widgets';
import React, { useState, useEffect } from 'react';
import { Supervisor } from 'service';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import ArthaGateway from 'service/ArthaGateway';
import moment from 'moment';

export const Reports = () => {
    const user = useSelector((state: RootState) => state.appdata);
    const [supervisor, setSupervisor] = useState<Supervisor>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [jobOrders, setJobOrders] = useState<any[]>([]);
    const [durationId, setDurationId] = useState<string>("4");
    const [from, setFrom] = useState<string>(moment().format("YYYY-MM-DD"));
    const [to, setTo] = useState<string>(moment().format("YYYY-MM-DD"));
    const [brandId, setBrandId] = useState<number>(0);
    const [brands, setBrands] = useState<any[]>([]);
    const [groupId, setGroupId] = useState<number>(0);
    const [groups, setGroups] = useState<any[]>([]);
    const [masterId, setMasterId] = useState<number>(0);
    const [masters, setMasters] = useState<any[]>([]);
    const [status, setStatus] = useState<number>(-1);
    const [pageNumber, setPageNumber] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(25);
    const [isJobOrderLoading, setIsJobOrderLoading] = useState<boolean>(true);
    const [jobOrder, setJobOrder] = useState<any>();
    const [totalPages, setTotalPages] = useState<number>(5);

    useEffect(() => {
        getJobOrders();
      }, [pageNumber]);

    useEffect(() => {
        if (user.profile) {
            setSupervisor(user.profile.profile);
            loadBrands();
            setIsLoading(false);
        }
    }, [user]);

    const loadBrands = () => {
        ArthaGateway.getAllBrands().then((data) => {
            setBrands(data);
        });
    }

    useEffect(() => {
        setGroups([]);
        if (brandId > 0) {
            ArthaGateway.getAllGroups(brandId).then((data) => {
                setGroups(data);
            });
        }
        else {
            setMasterId(0);
            setGroupId(0);
        }
    }, [brandId]);

    useEffect(() => {
        setMasters([]);
        if (brandId > 0 && groupId > 0) {
            ArthaGateway.getAllMasters(brandId, groupId).then((data) => {
                setMasters(data);
            });
        }
        else {
            setMasterId(0);
        }
    }, [groupId]);

    useEffect(() => {
        getJobOrders();
        setJobOrders([]);
        if(pageNumber > 1) {
            setPageNumber(1);
        }
        else {
            getJobOrders();
        }
    }, [durationId, brandId, groupId, masterId, status]);

    function getJobOrders(): void {
        setIsLoading(true);
        ArthaGateway.getJobOrders(durationId, from, to, brandId, groupId, masterId, status, null, pageNumber, pageSize).then((data) => {
            data.entries.map((jobOrder) => jobOrder.isExpanded = false);
            if(pageNumber == 1) {
                setJobOrders(data.entries);
            }
            else {
                setJobOrders([...jobOrders, ...data.entries]);
            }
            setTotalPages(data.totalPages);
            setIsLoading(false);
        });
    }

    function getJobOrder(jobOrder): void {
        toggleExapnd(jobOrder);

        setJobOrder(null);
        if(jobOrder.isExpanded) {
            setIsJobOrderLoading(true);
            ArthaGateway.getJobOrder(jobOrder.id).then((data) => {
                setJobOrder(data);
                setIsJobOrderLoading(false);
            });
        }
    }

    const toggleExapnd = (target) => {
            jobOrders.map((jobOrder) => {
                if(jobOrder.id === target.id) {
                    jobOrder.isExpanded = !jobOrder.isExpanded;
                }
                else {
                    jobOrder.isExpanded = false;
                }
            });
            setJobOrders([...jobOrders]);
        }

    const getStatusClass = (status) => {
        switch (status) {
            case 'New': return 'bg-warning';
            case 'Finished': return 'bg-success';
            case 'Dispatched': return 'bg-success';
            case 'Running': return 'bg-primary';
            case 'Cancelled': return 'bg-danger';
            case 'New': return 'bg-success';
            default: return 'bg-secondary';
        }
    }

    return (
        <>
            <div className="page-reports">
                <div className="form-group">
                    <label>Duration</label>
                    <select className="form-control" id="duration" value={durationId} onChange={(e) => setDurationId(e.target.value)}>
                        <option value="2024">FY 2024-2025</option>
                        <option value="2023">FY 2023-2024</option>
                        <option value="2022">FY 2022-2023</option>
                        <option value="2021">FY 2021-2022</option>
                        <option value="2020">FY 2020-2021</option>
                        <option value="2019">FY 2019-2020</option>
                        <option value="2018">FY 2018-2019</option>
                        <option value="0">Today</option>
                        <option value="1">Yesterday</option>
                        <option value="2">This Week</option>
                        <option value="3">Last Week</option>
                        <option value="4">This Month</option>
                        <option value="5">Last Month</option>
                        <option value="6">Last 2 Month</option>
                        <option value="7">Last 3 Month</option>
                        <option value="8">Last Year</option>
                        <option value="9">Last 1 Year</option>
                        <option value="-1">Other</option>
                    </select>
                </div><div className="form-group">
                    <label>Status</label>
                    <select className="form-control" id="duration" value={status} onChange={(e) => setStatus(parseInt(e.target.value))}>
                        <option value="-1">All</option>
                        <option value="0">New</option>
                        <option value="1">Running</option>
                        <option value="3">Hold</option>
                        <option value="4">Cancelled</option>
                        <option value="2">Finished</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Brand</label>
                    <select className="form-control" value={brandId} onChange={(e) => setBrandId(parseInt(e.target.value))}>
                        <option value="0">All</option>
                        {brands.map((brand) => (
                            <option key={brand.id} value={brand.id}>{brand.name}</option>
                        ))}
                    </select>
                </div>
                {brandId > 0 &&
                    <div className="form-group">
                        <label>Group</label>
                        <select className="form-control" value={groupId} onChange={(e) => setGroupId(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {groups.map((group) => (
                                <option key={group.id} value={group.id}>{group.name}</option>
                            ))}
                        </select>
                    </div>
                }
                {groupId > 0 &&
                    <div className="form-group">
                        <label>Master</label>
                        <select className="form-control" value={masterId} onChange={(e) => setMasterId(parseInt(e.target.value))}>
                            <option value="0">All</option>
                            {masters.map((master) => (
                                <option key={master.id} value={master.id}>{master.name}</option>
                            ))}
                        </select>
                    </div>
                }
                <div className="accordion" id="dealerAccordion" style={{ marginBottom: 20, marginTop: 30 }}>
                    {jobOrders && jobOrders.map((jo) => (
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={jo.id.toString()} onClick={() => getJobOrder(jo)}>
                                <div className={jo.isExpanded === true ? "accordion-button active bg-secondary" : "accordion-button collapsed"}>
                                    <div style={{position: "relative"}}>
                                        <span style={{ fontSize: "1.5rem" }}><b>{ jo.voucherNumber }</b></span>
                                        <div style={{ marginLeft: "10px" }} className={'badge '+ getStatusClass(jo.status) +' badge-small badge-notice'}>{ jo.status }</div>
                                        <div style={{ fontWeight: 'normal', fontSize: '0.8rem' }}>{ jo.brand }</div>
                                        <div style={{ fontWeight: 'normal', fontSize: '0.8rem' }}>{ jo.master }</div>
                                        <div className="date-info">{moment(jo.date).format("DD MMM YYYY")}</div>
                                    </div>
                                </div>
                            </h2>
                            <div id={"d"+ jo.id.toString()} className={jo.isExpanded === true ? "accordion-collapse show" : "accordion-collapse collapse"} aria-labelledby={"d"+ jo.id.toString()}>
                                <div className="accordion-body">                                    
                                    {isJobOrderLoading &&
                                        <Loader />
                                    }
                                    {!isJobOrderLoading && jobOrder && (
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                Brand
                                                <div className="fw-bold">{jobOrder.brand}</div>
                                            </li>
                                            <li className="list-group-item">
                                                Master
                                                <div className="fw-bold">{jobOrder.master}</div>
                                            </li>
                                            <li className="list-group-item">
                                                Date
                                                <div className="fw-bold">{moment(jobOrder.date).format("DD MMM YYYY")}</div>
                                            </li>
                                            <li className="list-group-item">
                                                Voucher Number
                                                <div className="fw-bold">{jobOrder.voucherNumber}</div>
                                            </li>
                                            <li className="list-group-item">
                                                Status
                                                <div className="fw-bold">{jobOrder.status}</div>
                                            </li>
                                            <li className="list-group-item">
                                                Total Quantity
                                                <div className="fw-bold">{jobOrder.totalQuantity}</div>
                                            </li>
                                            <li className="list-group-item">                                                
                                                <table className="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>Product</th>
                                                            <th>Quantity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {jobOrder.items.map((item) => (
                                                            <tr key={item.id}>
                                                                <td>{item.productName}</td>
                                                                <td>{item.quantity}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                
                {(totalPages > pageNumber) &&   
                    <>                 
                    {isLoading &&
                        <div className="btn btn-dark w-100 text-center">
                            <div className="spinner-border text-light" role="status">
                            </div>
                        </div>
                    }
                    {!isLoading &&
                        <div className="btn btn-dark w-100 text-center" onClick={() => setPageNumber(pageNumber + 1)}>Load More...</div>
                    }
                    </>
                }
            </div>
        </>
    );
};