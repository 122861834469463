import axios from 'axios';
import { app_version } from './Utils';
import { get } from 'http';

export const serverUrl = `${process.env.REACT_APP_SERVER_URL || ''}`;

axios.defaults.headers.post['Content-Type'] = 'application/json';

const ArthaGateway = {
    validateLogin: async function (userName, password) {
        try {
            const payload = { name: userName, loginName: userName, password: password, id: 0 };
            let response = await axios.post(serverUrl + '/user/validate', payload);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::validateLogin ->" + error);
            throw error;
        }
        return false;
    },
    loadSupervisors: async function () {
        try {
            let response = await axios.get(serverUrl + '/master/supervisor');
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getJobDetails ->" + error);
            throw error;
        }
        return undefined;
    },
    getJobDetails: async function (orderNumber) {
        try {
            const params = { orderNumber };
            let response = await axios.get(serverUrl + '/voucher/jobOrder/orderNumber', { params });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getJobDetails ->" + error);
            throw error;
        }
        return undefined;
    },
    saveProduction: async function (production) {
        try {
            let response = await axios.post(serverUrl + '/voucher/production', production);
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::saveProduction ->" + error);
            throw error;
        }
        return undefined;
    },
    getProduction: async function (durationId, 
        series,
        from,
        to,
        brandId,
        groupId,
        masterId,
        stageId,
        supervisorId,
        jobOrderNumber) {
        try {
            const params = {
                durationId,
                series,
                from,
                to,
                brandId,
                groupId,
                masterId,
                stageId,
                supervisorId,
                jobOrderNumber,
                pageNumber: 1,
                pageSize: 10000,
                type: 0
            };
            let response = await axios.get(serverUrl + '/voucher/production', { params });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getDealer ->" + error);
            throw error;
        }
        return undefined;
    },
    getJobOrders: async function (durationId, 
        from,
        to,
        brandId,
        groupId,
        masterId,
        status,
        orderNumber,
        pageNumber = 1,
        pageSize = 100) {
        try {
            const params = {
                durationId,
                from,
                to,
                brandId,
                groupId,
                masterId,
                status,
                orderNumber,
                pageNumber: pageNumber,
                pageSize: pageSize,
                fields: 'Id,Date,VoucherNumber,Status,Brand,Master,TotalQuantity'
            };
            let response = await axios.get(serverUrl + '/voucher/joborder', { params });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getDealer ->" + error);
            throw error;
        }
        return undefined;
    },    
    getJobOrder: async function (id) {
        try {
            const params = { includeProduction: true };
            let response = await axios.get(serverUrl + '/voucher/joborder/'+ id, { params });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getDealer ->" + error);
            throw error;
        }
        return undefined;
    },
    getAllBrands: async function () {
        try {
            let response = await axios.get(serverUrl + '/master/product/brand');
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getAllBrands ->" + error);
            throw error;
        }
        return undefined;
    },
    getAllGroups: async function (brandId) {
        try {
            const params = { brandId };
            let response = await axios.get(serverUrl + '/master/product/group', { params });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getAllGroups ->" + error);
            throw error;
        }
        return undefined;
    },
    getAllMasters: async function (schoolId, groupId) {
        try {
            const params = { schoolId, groupId };
            let response = await axios.get(serverUrl + '/master/productMaster/extract', { params });
            if (response) {
                return response.data;
            }
        } catch (error) {
            console.error("Error in ArthaGateway::getAllMasters ->" + error);
            throw error;
        }
        return undefined;
    }

}

export default ArthaGateway;