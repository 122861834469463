import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import { Dashboard, Login, JobDetails, Reports, BarcodeHandler } from './Pages';
import '../../node_modules/bootstrap/dist/css/bootstrap.css';
import '../../node_modules/bootstrap/dist/js/bootstrap.js';
import '../scss/style.scss'
import 'bootstrap'
import logo from "../assets/logo.png"
import { ActionCreators } from '../store/actionCreators';
import { RootState } from 'store/store';
import { BottomBar } from './Widgets';
import moment from 'moment';
import { NavLink } from 'react-router-dom';

function App() {
  const user = useSelector((state: RootState) => state.appdata);
  const dispatch = useDispatch();

  const checkLocalStorage = () => {
    console.log("Checking local storage");
    let user_data = localStorage.getItem("user");
    if(user_data && user_data.length > 0) {
      let login_time = localStorage.getItem("login_time");
      if(login_time && login_time.length > 0) {
        let last_login_time = moment(login_time);
        if(moment().diff(last_login_time, 'days') === 0) {
          let user_info = JSON.parse(user_data);
          dispatch(ActionCreators.login(user_info));
        }
      }
    }
  }

  const isAuthenticated = () => {
    if (!user.isUserLoggedIn) {
      checkLocalStorage();
      if (!user.isUserLoggedIn) {
        return false;
      }
    }
    return true;
  }

  const PrivateRoute = ({children}) => isAuthenticated() ? children : <Login />
  
  return (
    <div id="app">
      <Router>
        <nav className="navbar sticky-top navbar-dark bg-dark">
          <div className="container">
            <a className="navbar-brand" href="/">
              <img src={logo} width="30" height="25" alt="" style={{ marginRight: 10, backgroundColor: 'white' }} />
              Scholar Clothing
            </a>
          </div>
        </nav>
        <div className="container">
          <div className="row" style={{ marginTop: 10, marginBottom: 60 }}>
              <Routes>
                <Route path="/" element={<PrivateRoute><Dashboard /></PrivateRoute> } />
                <Route path="/jobOrder/:orderNumber" element={<PrivateRoute><JobDetails /></PrivateRoute> } />
                <Route path="/reports" element={<Reports /> } />
                <Route path="/login" element={ <Login /> } />
              </Routes>
          </div>
        </div>
        {user.isUserLoggedIn &&
          <BottomBar />
        }      
      </Router>
    </div>
  );
}

export default App;
